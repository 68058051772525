<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/lottery"
          ><i class="fas fa-chevron-left"></i> แทงหวย</router-link
        >
      </div>
      <div
        class="p-2 w-100 bg-light main-content align-self-stretch"
        style="min-height: calc((100vh - 140px) - 50px)"
      >
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-2
            px-3
            xtarget
            col-lotto
            mb-5
          "
        >
          <h3>{{ yeeki.subCatName }}</h3>
          <div class="row">
            <div
              class="col-6 col-sm-6 col-md-6 col-lg-3"
              v-for="item in getSubCatYeekiAviliableList
                .filter((e) => !getIsCloseYeekee(e.timeEnd, (getSubCatYeekiAviliableList.length > 88) ? 5 : 15))
                .reverse()"
              v-bind:key="item.id"
            >
              <router-link
                :to="
                  getIsCloseYeekee(item.timeEnd, 2)
                    ? `/member/lottery/yeeki-result/${catalogId}/${item.yeekiRoundID}`
                    : (getIsCloseYeekee(item.timeEnd, 0) ? `/member/lottery/yeeki-fire/${catalogId}/${item.yeekiRoundID}` : `/member/lottery/yeeki/${catalogId}/${item.yeekiRoundID}`)
                "
              >
                <div
                  class="lotto-card"
                  v-bind:class="getIsClose(item.timeEnd) ? 'lotto-close' : ''"
                >
                  <div v-bind:class="(getSubCatYeekiAviliableList.length > 88) ? 'ribbon-lotto-express' : '' " class="ribbon-lotto" >
                    <span class="">{{ item.yeekiRoundNum }}</span>
                  </div>
                  <div class="lotto-head" v-bind:class="(getSubCatYeekiAviliableList.length > 88) ? 'lotto-yeekee-express' : 'lotto-yeekee' ">
                    <h5>{{ yeeki.subCatName }}</h5>
                    <span class="badge badge-dark">ปิดรับ {{ getCloseTime(item.timeEnd) }}</span>
                  </div>
                  <div class="lotto-time" v-if="getIsClose(item.timeEnd)">
                    <i class="fas fa-ban"></i><span>ยังไม่รับแทง</span>
                  </div>
                  <div class="lotto-time text-success" v-else>
                    <i class="sn-icon sn-icon--daily2"></i
                    ><span
                      class="countdown"
                      data-finaldate="1610980200000"
                      v-html="calTime(item.timeEnd)"
                    ></span>
                  </div>
                </div>
              </router-link>
            </div>
            <div
              class="col-6 col-sm-6 col-md-6 col-lg-3"
              v-for="item in getSubCatYeekiAviliableList
                .filter((e) => getIsCloseYeekee(e.timeEnd, 2))
                .reverse()"
              v-bind:key="item.id"
            >
              <router-link
                :to="
                  getIsCloseYeekee(item.timeEnd, 2)
                    ? `/member/lottery/yeeki-result/${catalogId}/${item.yeekiRoundID}`
                    : `/member/lottery/yeeki/${catalogId}/${item.yeekiRoundID}`
                "
              >
                <div
                  class="lotto-card"
                  v-bind:class="getIsCloseYeekee(item.timeEnd, 2) ? 'lotto-close' : ''"
                >
                  <div class="ribbon-lotto">
                    <span class="">{{ item.yeekiRoundNum }}</span>
                  </div>
                  <div class="lotto-head lotto-yeekee">
                    <h5>{{ yeeki.subCatName }}</h5>
                    <span class="badge badge-dark">ปิดรับ {{ getCloseTime(item.timeEnd) }} </span>
                  </div>
                  <div class="lotto-time" v-if="getIsCloseYeekee(item.timeEnd, 2)">
                    <i class="fas fa-ban"></i><span>ยังไม่รับแทง</span>
                  </div>
                  <div class="lotto-time text-success" v-else>
                    <i class="sn-icon sn-icon--daily2"></i
                    ><span
                      class="countdown"
                      data-finaldate="1610980200000"
                      v-html="calTime(item.timeEnd)"
                    ></span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  async created () {
    setInterval(() => {
      this.getNow()
    }, 1000)
    this.catalogId = this.$route.params.catalog
    this.setLoading(true)
    if (this.getYeekiCatalogList.length === 0) {
      await this.getYeekiCatalog()
    }
    await this.getYeekiRound(this.catalogId)
    this.yeeki = this.getYeekiCatalogList.find(
      (e) => Number(e.subCatID) === Number(this.catalogId)
    )
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['getYeekiCatalog', 'setLoading', 'getYeekiRound']),
    calTime (value) {
      const now = this.currentDate ? this.currentDate : ''
      const final = moment(value).diff(now)
      if (final > 0) {
        const hours = Math.floor(
          (final % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        let minutes = Math.floor((final % (1000 * 60 * 60)) / (1000 * 60))
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((final % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
      } else {
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getIsCloseYeekee (currentX, min) {
      const now = this.currentDate !== null ? this.currentDate : ''
      const current = moment(currentX).add(min, 'minutes')
      const final = moment(current).diff(now)
      if (final > 0) {
        return false
      } else {
        return true
      }
    },
    getIsClose (current) {
      const now = this.currentDate !== null ? this.currentDate : ''
      const final = moment(current).diff(now)
      if (final > 0) {
        return false
      } else {
        return true
      }
    },
    getCloseTime (time) {
      return moment(String(time)).format('HH:mm')
    },
    getTime (time) {
      const timer = moment(time)
      const min = timer.minutes().toString()
      const hour = timer.hours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}: ${minCal}`
    },
    getNow () {
      this.currentDate = moment()
    }
  },
  computed: {
    ...mapGetters(['getYeekiCatalogList', 'getSubCatYeekiAviliableList'])
  },
  data: function () {
    return {
      currentDate: moment(),
      catalogId: '',
      yeeki: {}
    }
  }
}
</script>
